import "./Content.css"
import FunnyGif from "./pictures/px.gif"

const About = (props) => {
    return ( 
        <div ref={props.Reference} className="about-box">
            <img className="content-pic" src = {FunnyGif} alt = "здесь должна быть моя фотка"></img>
            <div className="content-text" style={{marginLeft: '5%'}}>
            Привет! Меня зовут Иван Сохань, я студент 2 курса Санкт-Петербургского государственного университета на направлении "Искусственный интеллект и наука о данных".
            <br/>
            Помимо глубокого интереса к искусственному интеллекту, у меня есть многолетний опыт в программировании — я начал заниматься им с 8 класса. В 11 классе это помогло мне выиграть три олимпиады по программированию и поступить в СПбГУ.
            <br/>
            Недавно я увлекся фронтенд-разработкой и с этого лета активно изучаю современные технологии веб-разработки. На данный момент я стремлюсь получить опыт коммерческой разработки и активно ищу стажировки, чтобы применить свои знания на практике.
            </div>
        </div>
     );
}
 
export default About;